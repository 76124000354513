import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 0 88px;
    margin-bottom: 48px;
    display: flex;
    gap :32px;
    color: #32334D;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    p {
        width: 50%;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        gap : 8px;
        margin-bottom: 32px;
        padding: 0 164px;
        font-size: 14px;
        line-height: 20px; 
        p {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        padding: 0 16px;
    }
`