import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 48px 88px;
    background: #F3F3FF;
    margin-bottom: 32px;
    @media (max-width: 1024px) {
        padding: 32px 164px;
    }
    @media (max-width: 768px) {
        padding: 32px 16px;
    }
`
export const Title = styled.h3`
    margin-bottom: 18px;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; 
    letter-spacing: 0.1px;
    @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
    }
`
export const BlockContainer = styled.div`
    display: flex;
    gap : 32px;
    @media (max-width: 1024px) {
        gap: 8px;
        flex-wrap: wrap;
    }
`
export const Block = styled.div`
    display: flex;
    flex-direction: column;
    gap : 8px;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(29, 29, 91, 0.09);
    padding: 16px;
    width: 25%;
    box-sizing: border-box;
    span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
        text-transform: uppercase;
    }
    p {
        color: #32334D;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    @media (max-width: 1024px) {
        width: 49%;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`