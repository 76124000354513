import * as LUI from './styled'
import {ABOUT_US, CONTACT, SERVICES} from "../../../../utils/constants";
import {useState} from "react";

const Header = (props : {isTable : boolean, isMobile : boolean}) => {
    const {isTable, isMobile} = props
    const [open, setOpen] = useState(false)
    const handleSwitch = () => setOpen(prevState => !prevState)
    return (
        <LUI.Wrapper>
            <img src={'/logoIcon.svg'} alt={'logo'}/>
            <LUI.Container>
                {!isTable &&
                    <LUI.Items>
                        <a href={SERVICES}>services</a>
                        <a href={ABOUT_US}>about us</a>
                        <a href={CONTACT}>contacts</a>
                    </LUI.Items>
                }
                <LUI.Button href={CONTACT}>
                    work with us
                </LUI.Button>
                {isTable && <LUI.MenuIcon src={'/menuIcon.svg'} alt={'menu'} onClick={handleSwitch}/>}
            </LUI.Container>
            {open &&
                <LUI.Menu>
                    <LUI.MenuWrapper>
                        <LUI.Header style={{justifyContent: isMobile ? 'space-between' : 'right'}}>
                            {isMobile && <img src={'/logoIcon.svg'} alt={'logo'} style={{width: '51px', height: '40px'}}/>}
                            <img src={'/crossIcon.svg'} alt={'cross'} onClick={handleSwitch}/>
                        </LUI.Header>
                        <LUI.Body>
                            <a href={SERVICES} onClick={handleSwitch}>services</a>
                            <a href={ABOUT_US} onClick={handleSwitch}>about us</a>
                            <a href={CONTACT} onClick={handleSwitch} style={{border : 'none'}}>contacts</a>
                            <LUI.MenuButton href={CONTACT} onClick={handleSwitch}>
                                work with us
                            </LUI.MenuButton>
                        </LUI.Body>
                        <LUI.Contacts>
                            <a href={'mailto:FDXcapital@yahoo.com'}><img src={'/mailBlackIcon.svg'} alt={'main'}/>FDXcapital@yahoo.com</a>
                            <a href={'mailto:DanielFDX@fdx-capital.us'}><img src={'/humanBlackIcon.svg'} alt={'main'}/>DanielFDX@fdx-capital.us</a>
                            <a href={'tel:+16178993077'}><img src={'/phoneBlackIcon.svg'} alt={'phone'}/>+1-617-899-3077</a>
                        </LUI.Contacts>
                    </LUI.MenuWrapper>
                </LUI.Menu>
            }
        </LUI.Wrapper>
    )
}

export default Header