import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    padding: 0 88px;
    margin-bottom: 96px;
    @media (max-width: 1024px) {
        padding: 0 164px;
        margin-bottom: 64px;
    }
    @media (max-width: 768px) {
        padding: 0 17px;
    }
`
export const Background = styled.img`
    width: 100%;
    border-radius: 32px;
    @media (max-width: 1024px) {
        min-height: 380px;
        border-radius: 16px;
        object-fit:cover;
    }
    @media (max-width: 768px) {
        min-height: 380px;
    }
`
export const Title = styled.h3`
    position: absolute;
    left: 50%;
    top : 50px;
    transform: translate(-50%, 0);
    color: #FFFFFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; 
    text-transform: uppercase;
    text-align: center;
    @media (max-width: 1024px) {
        font-size: 26px;
        line-height: 30px; 
        top : 24px;
    }
`

