import * as LUI from './styled'
import {SERVICES_ID} from "../../../../utils/constants";

const Services = (props : {isTable : boolean}) => {
    const {isTable} = props
    return (
        <LUI.Wrapper id={SERVICES_ID}>
            <LUI.Button>
                Services
            </LUI.Button>
            <LUI.BlocksContainer>
                <LUI.LeftBlocks>
                    <img src={'/servicesImage.png'} alt={'services'}/>
                    <LUI.Item>
                        <h2>Technical Support and Consultation</h2>
                        <p>Have questions about installation, maintenance, or compatibility? Our team of experienced aviation professionals is here to provide technical support and consultation services to ensure you get the most out of your parts.</p>
                    </LUI.Item>
                    <LUI.Item>
                        <h2>Inventory Management</h2>
                        <p>Managing your aviation parts inventory can be complex. Streamline your operations with our inventory management services. We can help optimize stock levels, track usage patterns, and ensure you always have the parts you need on hand.</p>
                    </LUI.Item>
                </LUI.LeftBlocks>
                <LUI.RightBlocks>
                    <LUI.Item>
                        <h2>Parts Sourcing and Procurement</h2>
                        <p>Need a specific aviation part or component? Let us handle the sourcing and procurement process for you. Our extensive network of suppliers ensures that we can find even the most elusive parts quickly and efficiently.</p>
                    </LUI.Item>
                    <LUI.Item>
                        <h2>Custom Solutions</h2>
                        <p>Every aviation operation is unique. That's why we offer custom solutions tailored to your specific needs. Whether it's sourcing hard-to-find parts or developing specialized inventory management systems, we can help.</p>
                    </LUI.Item>
                    <LUI.Item>
                        <h2>Global Shipping and Logistics</h2>
                        <p>No matter where you are in the world, we can get your aviation parts to you quickly and securely. Our global shipping and logistics capabilities ensure timely delivery to America, Asia, Dubai, and beyond.</p>
                    </LUI.Item>
                    {!isTable &&
                        <LUI.Item>
                            <h2>Training and Education</h2>
                            <p>Stay ahead of the curve with our training and education programs. From product seminars to certification courses, we offer resources to keep you informed and empowered in the ever-evolving world of aviation technology.</p>
                        </LUI.Item>
                    }
                </LUI.RightBlocks>
            </LUI.BlocksContainer>
            {isTable &&
                <LUI.Item>
                    <h2>Training and Education</h2>
                    <p>Stay ahead of the curve with our training and education programs. From product seminars to certification courses, we offer resources to keep you informed and empowered in the ever-evolving world of aviation technology.</p>
                </LUI.Item>
            }
        </LUI.Wrapper>
    )
}

export default Services