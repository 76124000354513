import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 0 88px;
    display: flex;
    gap: 32px;
    margin-bottom: 96px;
    @media (max-width: 1024px) {
        padding: 0 164px;
        flex-direction: column;
        gap : 16px;
        margin-bottom: 64px;
    }
    @media (max-width: 768px) {
        padding: 0 16px;
    }
`

export const LeftBlock = styled.div`
    width: 50%;
    color: #32334D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    display: flex;
    flex-direction: column;
    gap : 8px;
    @media (max-width: 1024px) {
        width: 100%;
        font-size: 14px;
        line-height: 20px; 
    }
`
export const Button = styled.button`
    width: fit-content;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 70px;
    background: #00ABC1;
    color: #FFFFFF;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; 
    letter-spacing: 0.5px;
    border: none;
    margin-bottom: 24px;
    &:hover {
        cursor: pointer;
    }
    @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 16px;
    }
`
export const RightBlock = styled.div`
    width: 50%;
    img {
        width: 100%;
        border-radius: 16px;
    }
    @media (max-width: 1024px) {
        width: 100%;
    }

`