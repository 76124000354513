import * as LUI from './main.styled'
import Header from "./components/Header";
import AboutUs from "./components/AboutUs";
import Secrets from "./components/Secrets";
import Blocks from "./components/Blocks";
import Information from "./components/Information";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import MainContent from "./components/MainContent";
import {useEffect, useState} from "react";

const Main = () => {
    const [isTable, setIsTable] = useState<boolean>(window.innerWidth <= 1024)
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)
    useEffect(() => {
        const handleResize = () => {
          setIsTable(window.innerWidth <= 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return (
        <LUI.Wrapper>
           <Header isTable={isTable} isMobile={isMobile}/>
           <MainContent/>
           <AboutUs/>
           <Secrets/>
           <Blocks/>
           <Information/>
           <Services isTable={isTable}/>
           <Contact/>
           <Footer/>
        </LUI.Wrapper>
    )
}

export default Main