import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
    padding: 16px 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1024px) {
        padding: 10px 180px;
        img {
            width: 51px;
            height: 40px;
        }
    }
    @media (max-width: 768px) {
        padding: 10px 16px;
    }
`
export const Container = styled.div`
    display: flex;
    gap : 56px;
    align-items: center;
    @media (max-width: 1024px) {
        gap : 16px;
    }
`
export const Items = styled.div`
    display: flex;
    gap : 24px;
    a {
        color: #000000;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px; 
        letter-spacing: 0.1px;
        text-decoration: none;
    }
    a:hover {
        cursor: pointer;
        color: #FFCA00;
    }
    align-items: center;
`
export const Button = styled.a`
    padding: 8px 24px;
    border-radius: 8px;
    text-decoration: none;
    background: #FFCA00;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; 
    border: none;
    &:hover {
        cursor: pointer;
    }
    @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 18px;
    }
`

export const MenuIcon = styled.img`
    width: 24px !important;
    height: 24px !important;
    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`

export const Menu = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top : 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
    animation: ${fadeIn} 0.5s ease forwards;
    z-index: 10000;
`
export const MenuWrapper = styled.div`
    background: #FAFBFF;
    position: absolute;
    right: 0;
    top : 0;
    height: 100%;
    width: 50%;
    @media (max-width: 768px) {
        width: 100%;
    }
`
export const Header = styled.div`
    img {
        width: 24px;
        height: 24px;
    }
    img:hover {
        cursor: pointer;
        opacity: 0.5;
    }
    display: flex;
    justify-content: right;
    padding: 10px 16px;
    margin-right: 160px;
    height: 60px;
    box-sizing: border-box;
    align-items: center;
    @media (max-width: 1024px) {
        margin-right: 40px;
    }
    @media (max-width: 768px) {
        margin-right: 0;
    }
`
export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 336px;
    margin-left: 50px;
    margin-top: 180px;
    box-sizing: border-box;
    a {
        width: 100%;
        text-align: center;
        text-decoration: none;
        color:  #000000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding: 16px 0;
        border-bottom: 2px solid #DBE0EF;
    }
    a:hover {
        cursor: pointer;
        color: #FFCA00;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
        padding: 0 16px;
    }
`
export const MenuButton = styled.a`
    width: 100%;
    padding: 8px 24px !important;
    border-radius: 8px;
    background: #FFCA00;
    text-decoration: none;
    box-sizing: border-box;
    color: #000000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border: none;
    margin-top: 16px;
    &:hover {
        cursor: pointer;
    }
`

export const Contacts = styled.div`
    color: #FFFDFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    margin-left: 50px;
    a {
        display: flex;
        gap : 8px;
        align-items: center;
        color: black;
        text-decoration: none;
        img {
            width: 16px;
            height: 16px;
        }
    }
    @media (max-width: 1024px) {
        height: 70%;
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        height: 70%;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        margin-left: 0;
        padding-left: 0;
    }
`