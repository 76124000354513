import * as LUI from './styled'

const MainContent = () => {
    return (
        <LUI.Wrapper>
            <LUI.Background src={'/mainContentImage.png'} alt={'main content'}/>
            <LUI.Title>We are first and for most</LUI.Title>
        </LUI.Wrapper>
    )
}

export default MainContent