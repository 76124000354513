import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 48px 88px;
    background: #E9E9FA;
    @media (max-width: 1024px) {
        padding: 32px 164px;
    }
    @media (max-width: 768px) {
        padding: 32px 16px;
    }
`

export const BlocksContainer = styled.div`
    display: flex;
    gap : 32px;
    @media (max-width: 1024px) {
        gap : 8px;
        margin-bottom: 8px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const Button = styled.button`
    padding: 8px 24px;
    border-radius: 70px;
    background: #007965;
    border: none;
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px; 
    letter-spacing: 0.5px;
    margin-bottom: 32px;
    @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 30px; 
        margin-bottom: 16px;
    }
`
export const LeftBlocks = styled.div`
    display: flex;
    flex-direction: column;
    gap : 32px;
    width: 50%;
    img {
        object-fit:cover;
        height: 48%;
        border-radius: 16px;
    }
    @media (max-width: 1024px) {
        gap : 8px;
        img {
            height: 33%;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`
export const RightBlocks = styled.div`
    display: flex;
    flex-direction: column;
    gap : 32px;
    @media (max-width: 1024px) {
        gap : 8px;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`
export const Item = styled.div`
    height: 33%;
    border-radius: 16px;
    background:  #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(29, 29, 91, 0.09);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap : 8px;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.1px;
    h2 {
        font-size: 18px;
        line-height: 26px;
    }
    p {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 1024px) {
        
    }
`