import * as LUI from './styled'

const Footer = () => {
    return (
        <LUI.Wrapper>
            <LUI.Title>Contacts</LUI.Title>
          <LUI.Contacts>
            <a href={'mailto:FDXcapital@yahoo.com'}><img src={'/mailIcon.svg'} alt={'main'}/>FDXcapital@yahoo.com</a>
            <a href={'mailto:DanielFDX@fdx-capital.us'}><img src={'/humanIcon.svg'} alt={'main'}/>DanielFDX@fdx-capital.us</a>
            <a href={'tel:+16178993077'}><img src={'/phoneIcon.svg'} alt={'phone'}/>+1-617-899-3077</a>
          </LUI.Contacts>
          <LUI.Rights>
            <span>Copyright 2022-2024</span>
            <span>All Rights Reserved</span>
            </LUI.Rights>
        </LUI.Wrapper>
    )
}

export default Footer