import * as LUI from './about.us.styled'
import {ABOUT_US_ID} from "../../../../utils/constants";

const AboutUs = () => {
    return (
        <LUI.Wrapper id={ABOUT_US_ID}>
            <LUI.LeftBlock>
                <LUI.Button>
                    About us
                </LUI.Button>
                <p>FDX Capital is a premier provider of aviation parts and components, catering to clients across America, Asia, and Dubai. With a steadfast commitment to excellence and a global reach, we specialize in delivering high-quality products and exceptional service to the aviation industry.</p>
                <p>At FDX Capital, integrity and reliability are at the core of everything we do. Our team is dedicated to upholding the highest standards of quality, safety, and compliance, ensuring that our customers can trust us with their most critical aviation needs. From airlines to maintenance facilities, our clients rely on us to provide reliable solutions that keep their fleets flying safely and efficiently.</p>
            </LUI.LeftBlock>
            <LUI.RightBlock>
                <img src={'/aboutUsImage.png'} alt={'about us'}/>
            </LUI.RightBlock>
        </LUI.Wrapper>
    )
}

export default AboutUs