import * as LUI from './styled'

const Secrets = () => {
    return (
        <LUI.Wrapper>
            <LUI.Title>
                Secrets to
                <LUI.YellowButton>
                    Successful
                </LUI.YellowButton>
                Aircraft Parts Consolidation
                <LUI.PinkButton>
                    in the USA
                </LUI.PinkButton>
            </LUI.Title>
            <LUI.Content>
                <p>In the modern world, the aviation industry is one of the most competitive and dynamically developing industries. Thanks to rapid advances in technology and globalization, aviation has become an integral part of international business and trade.</p>
                <p>Companies specializing in the sale of aircraft parts often have to deal with the need to participate in the processes of cargo consolidation, grouping of orders and consolidation of spare parts and components in other countries, including the United States of America.</p>
            </LUI.Content>
        </LUI.Wrapper>
    )
}

export default Secrets