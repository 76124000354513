import styled from "styled-components";

export const Wrapper = styled.div`
    background: #0F101A;
    padding: 40px 88px 0 88px;
    @media (max-width: 1024px) {
        padding: 32px 164px 0px 164px;
    }
    @media (max-width: 768px) {
        padding: 32px 16px 0px 16px;
    }
`
export const Title = styled.h3`
    color: #FFFFFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; 
    margin-bottom: 8px;
`
export const Contacts = styled.div`
    color: #FFFDFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
    display: flex;
    gap : 24px;
    margin-bottom: 32px;
    a {
        display: flex;
        gap : 8px;
        align-items: center;
        color: white;
        text-decoration: none;
    }
    @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
    }
`
export const Rights = styled.div`
    padding: 12px 0;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1px;
    display: flex;
    justify-content: space-between;
`