import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap : 32px;
    padding: 0 88px;
    margin-bottom: 32px;
    @media (max-width: 1024px) {
        gap : 16px;
        padding: 0 164px;
    }
    @media (max-width: 768px) {
        padding: 0 16px;
    }
`
export const Title = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap : 16px;
    color:  #000000;
    white-space: nowrap;
    align-items: center;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.5px;
    button {
        border-radius: 70px;
        color: #FFFFFF;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; 
        letter-spacing: 0.5px;
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
    }
    @media (max-width: 1024px) {
        font-size: 22px;
        gap : 8px;
        line-height: 30px;
        button {
            font-size: 22px;
            line-height: 30px;
        }
    }
    @media (max-width: 768px) {
        gap : 12px;
    }
`
export const YellowButton = styled.button`
    background-color: #FFCA00;
    transform: rotate(-3deg);
`
export const PinkButton = styled.button`
    background-color: #FF5CAF;
    transform: rotate(3deg);
`
export const Content = styled.div`
    display: flex;
    gap: 32px;
    color: #32334D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.1px;
    @media (max-width: 1024px) {
        flex-direction: column;
        gap : 8px;
        font-size: 14px;
        line-height: 20px;
    }
`