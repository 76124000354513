import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
    padding: 48px 88px;
    gap : 32px;
    display: flex;
    @media (max-width: 1024px) {
        padding: 32px 164px;
        gap : 24px;
        flex-direction: column;
    }
    @media (max-width: 768px) {
        padding: 32px 16px;
        
    }
`
export const LeftBlock = styled.div`
    width: 50%;
    color:  #000000;
    h3 {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    @media (max-width: 1024px) {
        width: 100%;
        h3 {
            font-size: 22px;
            line-height: 30px;
        }
    }
`
export const RightBlock = styled.div`
    width: 50%;
    @media (max-width: 1024px) {
        width: 100%;
    }
`
export const Form = styled.form`
    width: 100%;
`
export const Button = styled.button`
    padding: 8px 24px;
    border-radius: 8px;
    background-color: #FFCA00;
    color : #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.1px;
    border: none;
    width: 100%;
    &:hover {
        cursor: pointer;
    }
`

export const Checkbox = styled.div`
    color: #32334D;
    padding: 8px 0;
    border-bottom: 2px solid #B4B4C3;
    margin-bottom: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap : 10px;
    img:hover {
        cursor: pointer;
    }
    
`

export const Loading = styled.p`
    width: 24px;
    height: 24px;
    animation: ${rotate} 1s linear infinite;
`
export const Success = styled.p`
    display: flex;
    align-items: center;
    gap : 10px;
    color: #32334D;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
`