import * as LUI from './styled'
import {useState} from "react";
import Input from "../../../../components/Input/Input";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {CONTACT_ID} from "../../../../utils/constants";
import {useSendMailMutation} from "../../../../api/mailService";

type TInputs = {
    name : string,
    email : string,
    message : string,
}

const Contact = () => {
    const [checked, setChecked] = useState<boolean>(false);
    const formMethods = useForm<TInputs>({
        mode: 'onSubmit',
        shouldUnregister: false
    })
    const { handleSubmit, reset } = formMethods
    const [sendMail, {isLoading, isSuccess}] = useSendMailMutation()
    const handleChecked = () => {
        setChecked((prevState) => !prevState)
    }
    const onSubmit: SubmitHandler<TInputs> = async (data) => {
        checked && await sendMail(data).then(() => reset())

    }
    return (
        <FormProvider {...formMethods}>
            <LUI.Wrapper id={CONTACT_ID}>
                <LUI.LeftBlock>
                    <h3>Work with us today!</h3>
                    <p>We are thrilled to potentially partner with you. Please fill out the application form below to tell us about your project needs and objectives. Provide as much detail as possible so we can tailor our approach to suit your requirements. Our team will review your submission and contact you promptly to discuss how we can collaborate to achieve your goals. We look forward to working together!</p>
                </LUI.LeftBlock>
                <LUI.RightBlock>
                    <LUI.Form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            title={"Name"}
                            name={"name"}
                            rules={{
                                minLength: 1,
                                maxLength: 255,
                                required: true
                            }}
                            transform={(text: string | undefined) => {
                                if (text) {
                                    const formattedText = text
                                        .slice(0, 255);
                                    return formattedText
                                }
                                return text
                            }}
                        />
                        <Input
                            title={"E-mail"}
                            name={"email"}
                            rules={{
                                minLength: 5,
                                maxLength: 255,
                                required: true,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            }}
                            transform={(text: string | undefined) => {
                                if (text) {
                                    const formattedText = text
                                        .slice(0, 255);
                                    return formattedText
                                }
                                return text
                            }}
                        />
                        <Input
                            title={"Your message"}
                            name={"message"}
                            rules={{
                                minLength: 1,
                                maxLength: 255,
                                required: true
                            }}
                            type={"textarea"}
                            transform={(text: string | undefined) => {
                                if (text) {
                                    const formattedText = text
                                        .slice(0, 255);
                                    return formattedText
                                }
                                return text
                            }}
                        />
                        <LUI.Checkbox>
                            <img src={checked ? "/checkBoxFilled.svg" : "/checkBoxEmpty.svg"} onClick={handleChecked} alt={"checkbox"}/>
                            I agree with the Privacy Policy
                        </LUI.Checkbox>
                        {!isSuccess && !isLoading &&
                            <LUI.Button type={"submit"} >
                                Send
                            </LUI.Button>
                        }
                        {isLoading &&
                            <LUI.Loading>
                                <img src={"/loadIcon.png"} alt={"loading"}/>
                            </LUI.Loading>}
                        {isSuccess &&
                            <LUI.Success>
                                <img src={"/okIcon.svg"} alt={"ok"}/>
                                Сообщение отправлено
                            </LUI.Success>}
                    </LUI.Form>
                </LUI.RightBlock>
            </LUI.Wrapper>
        </FormProvider>
    )
}

export default Contact