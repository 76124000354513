import styled from "styled-components";

export const InputBlock = styled.div`
    width: 100%;
    margin-bottom: 16px;
    .field {
        border: none;
        font-weight: 600;
        border-bottom: 2px solid #B4B4C3;
        background-color: transparent;
        width: 100%;
        padding: 8px 0;
        font-size: 16px;
        line-height: 24px;
        transition: 0.3s;
    }
    .field:focus {
        outline: none;
        border-bottom: 2px solid #B4B4C3;
    }
    .field::placeholder {
        color : #B4B4C3;
        font-weight: 600;
    }
    .field.textArea {
        font-family: inherit;
        overflow-x: hidden;
        overflow-y: scroll;
        height: 24px;
    }
    .field.textArea::-webkit-scrollbar {
        display: none;
    }
    .field.error {
        border-bottom: 2px solid #FB3434;
    }
    .field.error::placeholder {
        color : #FB3434;
    }
`
