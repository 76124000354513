import * as LUI from './styled'

const Information = () => {
    return (
        <LUI.Wrapper>
            <p>In conclusion, it should be noted that the consolidation of aircraft parts in the United States implies an undeniably complex and responsible process that requires a careful approach and professional execution. Compliance with all necessary rules and recommendations, choosing a reliable partner and control over the process will help to successfully carry out the unification process and deliver the cargo safe and sound. </p>
            <p>Be alert and careful and your business will thrive internationally, keeping the aviation industry moving forward.Secrets to Successful Aircraft Parts Consolidation in the USA.</p>
        </LUI.Wrapper>
    )
}

export default Information