import { configureStore } from '@reduxjs/toolkit'
import {MailApi} from "./mailService";

export const store = configureStore({
    reducer: {
        [MailApi.reducerPath]: MailApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(MailApi.middleware)
})
